import axios from 'axios';
import type { ExternalInsuranceEntry, GetInsuranceCodesResult } from './model';

export const getCodesFromBackend = (
  apiKey: string
): Promise<readonly ExternalInsuranceEntry[]> =>
  axios
    .get<GetInsuranceCodesResult>('/api/user/insurancecode/list', {
      params: { apiKey },
    })
    .then(({ data: { result } }) => result);
