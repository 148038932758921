/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo, useState } from 'react';
import Action from './Action';
import {
  Alert,
  AlreadyAddedCodes,
  CircularProgress,
  DuplicateCodes,
  NewCodes,
  Tab,
  TabPanel,
  Tabs,
} from '..';
import { useCodesFromBackend } from '../../api';
import { prettyCount, usePartition } from '../../helper';
import { useCodesFromSpreadsheet } from '../../spreadsheet';
import { spacingLg } from '../../style';
import type { Dispatch, SetStateAction } from 'react';
import type { VisibleError } from '../../model';

interface Props {
  readonly addError: (error: VisibleError) => void;
  readonly apiKey: string;
  readonly file: File;
  readonly importing: boolean;
  readonly setImporting: Dispatch<SetStateAction<boolean>>;
}

const Compare = ({
  addError,
  apiKey,
  file,
  importing,
  setImporting,
}: Props) => {
  const [backendCodes, loadingBackend, backendError, reloadBackendCodes] =
    useCodesFromBackend(apiKey);
  const [spreadsheetCodes, loadingSpreadsheet, spreadsheetError] =
    useCodesFromSpreadsheet(file);
  const partition = usePartition(spreadsheetCodes, backendCodes);
  const [tab, setTab] = useState(0);

  if (loadingBackend || loadingSpreadsheet) {
    return <CircularProgress />;
  }

  if (backendError || spreadsheetError) {
    return (
      <Alert severity='error'>
        {backendError?.message || spreadsheetError?.message}
      </Alert>
    );
  }

  if (!apiKey || !partition) {
    return null;
  }

  const { alreadyAdded, duplicateCount, duplicateCounts, newCodes } = partition;

  return (
    <>
      <div
        css={css`
          margin-bottom: ${spacingLg};
        `}
      >
        <Action
          addError={addError}
          apiKey={apiKey}
          importing={importing}
          partition={partition}
          reloadBackendCodes={reloadBackendCodes}
          setImporting={setImporting}
        />
      </div>
      <Tabs value={tab} setValue={setTab}>
        <Tab index={0} label={prettyCount('New Code', Object.keys(newCodes))} />
        <Tab
          index={1}
          label={prettyCount(
            'Already Imported Code',
            Object.keys(alreadyAdded)
          )}
        />
        <Tab index={2} label={prettyCount('Duplicate Code', duplicateCount)} />
      </Tabs>
      <TabPanel
        index={0}
        value={tab}
        css={css`
          flex-grow: 1;
        `}
      >
        <NewCodes newCodes={newCodes} />
      </TabPanel>
      <TabPanel
        index={1}
        value={tab}
        css={css`
          flex-grow: 1;
        `}
      >
        <AlreadyAddedCodes alreadyAdded={alreadyAdded} />
      </TabPanel>
      <TabPanel
        index={2}
        value={tab}
        css={css`
          flex-grow: 1;
        `}
      >
        <DuplicateCodes duplicateCounts={duplicateCounts} />
      </TabPanel>
    </>
  );
};

export default memo(Compare);
