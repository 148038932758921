/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '..';
import { prettyDate } from '../../helper';
import type { ExternalInsuranceEntry } from '../../api';

interface Props {
  /**
   * Insurance codes that are in the spreadsheet and are already in the backend.
   */
  readonly alreadyAdded: Readonly<Record<string, ExternalInsuranceEntry>>;
}

const AlreadyAddedCodes = ({ alreadyAdded }: Props) => (
  <table
    css={css`
      border-collapse: separate;
      border-spacing: 0;
      height: 100%;
      width: 100%;
    `}
  >
    <tbody>
      <td
        css={css`
          height: 100%;
          vertical-align: top;
        `}
      >
        <TableContainer
          css={css`
            height: 100%;
            max-height: 100%;
          `}
        >
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Insurance Code</TableCell>
                <TableCell align='right'>Date Imported</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(alreadyAdded).map(([code, { creation_time }]) => (
                <TableRow key={code}>
                  <TableCell>{code}</TableCell>
                  <TableCell align='right'>
                    {prettyDate(creation_time)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </td>
    </tbody>
  </table>
);

export default memo(AlreadyAddedCodes);
