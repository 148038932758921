import { useAsync } from 'react-use';
import { getCodesFromSpreadsheet } from '.';

export const useCodesFromSpreadsheet = (
  file: File
): readonly [readonly string[] | undefined, boolean, Error | undefined] => {
  const { value, loading, error } = useAsync(
    async () => getCodesFromSpreadsheet(file),
    [file]
  );

  return [loading || error ? undefined : value, loading, error];
};
