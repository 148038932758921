/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { AxiosError } from 'axios';
import pLimit from 'p-limit';
import { memo } from 'react';
import { useCounter } from 'react-use';
import { Alert, Button, LinearProgress, Typography, UploadIcon } from '..';
import { importInsuranceCode } from '../../api';
import { prettyCount, prettyNumber } from '../../helper';
import type { Dispatch, SetStateAction } from 'react';
import type { Partition, VisibleError } from '../../model';

interface Props {
  readonly addError: (error: VisibleError) => void;
  readonly apiKey: string;
  readonly importing: boolean;
  readonly partition: Partition;
  readonly reloadBackendCodes: () => void;
  readonly setImporting: Dispatch<SetStateAction<boolean>>;
}

const maxConcurrency = 3;

const Action = ({
  addError,
  apiKey,
  importing,
  partition: { alreadyAdded, newCodes },
  reloadBackendCodes,
  setImporting,
}: Props) => {
  const [importedCount, { inc: addImportedCount }] = useCounter(0);
  const newCodeCount = Object.keys(newCodes).length;
  const alreadyAddedCount = Object.keys(alreadyAdded).length;

  if (newCodeCount > 0.5 * alreadyAddedCount) {
    return (
      <Alert severity='error'>
        You may have selected the wrong Insurance Pool.
      </Alert>
    );
  }

  if (importing) {
    return (
      <div
        css={css`
          height: 36.5px;
        `}
      >
        <Typography align='center'>
          {prettyNumber(importedCount)} / {prettyNumber(newCodeCount)} (
          {Math.round((100 * importedCount) / newCodeCount)}%)
        </Typography>
        <LinearProgress
          variant='determinate'
          value={importedCount / newCodeCount}
        />
      </div>
    );
  }

  if (!newCodeCount) {
    return null;
  }

  const doImport = () => {
    setImporting(true);

    const limit = pLimit(maxConcurrency);
    const promises = Object.keys(newCodes).map(async (code) =>
      limit(async () => {
        try {
          await importInsuranceCode({ apiKey, code });
          addImportedCount(1);
        } catch (error) {
          let message: string | undefined;
          if (error instanceof AxiosError) {
            message = error.response?.data;
          }
          if (!message && error instanceof Error) {
            message = error.message;
          }

          addError({
            message:
              `Failed to import insurance code “${code}”` +
              (message ? `: ${message}` : '.'),
          });
        }
      })
    );

    Promise.all(promises).finally(() => {
      setImporting(false);
      reloadBackendCodes();
    });
  };

  return (
    <Button onClick={doImport} variant='contained' startIcon={<UploadIcon />}>
      Import {prettyCount('Insurance Code', newCodeCount)}
    </Button>
  );
};

export default memo(Action);
