/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Tab as MuiTab } from '@mui/material';
import type { TabProps as MuiTabProps } from '@mui/material';
import { primaryBlack } from '../../style';

export interface TabProps extends MuiTabProps {
  readonly index: number | string;
}

export const Tab = (props: TabProps) => (
  <MuiTab
    id={`tab-${props.index}`}
    aria-controls={`tabpanel-${props.index}`}
    {...props}
    css={css`
      font-weight: bold;
      text-transform: none;

      &,
      &.Mui-selected {
        color: ${primaryBlack};
      }
    `}
  />
);
