import axios from 'axios';

interface Options {
  readonly apiKey: string;
  readonly code: string;
}

export const importInsuranceCode = ({ apiKey, code }: Options): Promise<void> =>
  axios.put(
    `/api/user/insurancecode/${encodeURIComponent(
      code
    )}?apiKey=${encodeURIComponent(apiKey)}`
  );
