/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo } from 'react';
import { AppBar, Container, Toolbar, Typography } from '.';
import { spacingLg } from '../style';

const Top = () => (
  <AppBar position='static'>
    <Container>
      <Toolbar disableGutters>
        <img
          src='/kry-logo-neg.svg'
          alt='Kry'
          height={38}
          width={74}
          css={css`
            padding-top: 6px;
            margin-right: ${spacingLg};
          `}
        />
        <Typography
          variant='h1'
          noWrap
          css={css`
            cursor: default;
          `}
        >
          Insurance Import
        </Typography>
      </Toolbar>
    </Container>
  </AppBar>
);

export default memo(Top);
