/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Container, ImportForm, ThemeProvider, Top } from './component';
import { theme } from './style';

export const App = () => (
  <ThemeProvider theme={theme}>
    <Top />
    <Container
      css={css`
        flex-grow: 1;

        display: flex;
        flex-direction: column;
      `}
    >
      <ImportForm />
    </Container>
  </ThemeProvider>
);
