import { useLocalStorage } from 'react-use';
import type { Dispatch, SetStateAction } from 'react';

export const useApiKeys = (): [
  readonly string[],
  Dispatch<SetStateAction<readonly string[] | undefined>>,
  () => void
] => {
  const [apiKeys, setApiKeys, remove] =
    useLocalStorage<readonly string[]>('apiKeys');

  return [apiKeys || [], setApiKeys, remove];
};
