/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useField } from 'formik';
import { sortBy } from 'lodash';
import { memo } from 'react';
import {
  Button,
  IconButton,
  KeyIcon,
  KeyOffIcon,
  MenuItem,
  TextField,
} from '..';
import { getPoolByApiKey, useApiKeys } from '../../helper';
import { spacingLg } from '../../style';

interface Props {
  readonly apiKey: string;
  readonly importing: boolean;
}

const SelectPool = ({ apiKey, importing }: Props) => {
  const [apiKeys, setApiKeys] = useApiKeys();
  const [, , { setValue: setApiKey }] = useField('apiKey');

  const doAdd = () => {
    const newApiKey = window.prompt('What is the API Key?');
    if (newApiKey) {
      setApiKeys([...apiKeys, newApiKey]);
      setApiKey(newApiKey);
    }
  };

  const doForget = () => {
    if (
      window.confirm(
        `Do you want your browser to forget the ${
          getPoolByApiKey(apiKey)?.name || apiKey
        } API Key?`
      )
    ) {
      setApiKeys(apiKeys.filter((otherKey) => otherKey !== apiKey));
      setApiKey('');
    }
  };

  return apiKeys.length ? (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${spacingLg};
      `}
    >
      <TextField
        label='Insurance Pool'
        name='apiKey'
        disabled={importing}
        required
        select
        css={css`
          flex: 1;
        `}
      >
        {sortBy(apiKeys, (apiKey) => getPoolByApiKey(apiKey)?.name).map(
          (apiKey) => (
            <MenuItem key={apiKey} value={apiKey}>
              {getPoolByApiKey(apiKey)?.name || apiKey}
            </MenuItem>
          )
        )}
      </TextField>
      {Boolean(apiKey) && (
        <IconButton
          onClick={doForget}
          type='button'
          title={`Forget ${getPoolByApiKey(apiKey)?.name || apiKey} API Key`}
          disabled={importing}
        >
          <KeyOffIcon color='primary' />
        </IconButton>
      )}
      <IconButton
        onClick={doAdd}
        type='button'
        title='Enter API Key for Another Insurance Pool'
      >
        <KeyIcon color='primary' />
      </IconButton>
    </div>
  ) : (
    <Button onClick={doAdd} variant='contained' startIcon={<KeyIcon />}>
      Enter API Key
    </Button>
  );
};

export default memo(SelectPool);
