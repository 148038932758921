import { useAsyncRetry } from 'react-use';
import { getCodesFromBackend } from '.';
import type { ExternalInsuranceEntry } from '.';

export const useCodesFromBackend = (
  apiKey: string
): readonly [
  readonly ExternalInsuranceEntry[] | undefined,
  boolean,
  Error | undefined,
  () => void
] => {
  const { value, loading, error, retry } = useAsyncRetry(
    async () => getCodesFromBackend(apiKey),
    [apiKey]
  );

  return [loading || error ? undefined : value, loading, error, retry];
};
