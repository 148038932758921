export interface TabPanelProps {
  readonly children: React.ReactNode | React.ReactNode[];
  readonly className?: string | undefined;
  readonly index: number | string;
  readonly value: number | string;
}

export const TabPanel = ({
  children,
  className,
  index,
  value,
}: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={index !== value}
    aria-labelledby={`tab-${index}`}
    id={`tabpanel-${index}`}
    className={className}
  >
    {index === value && children}
  </div>
);
