export const brandBlue = '#002845';

/**
 * Alpha colors from the Clinician Design System.
 *
 * @see https://kry:ClinicianDesignSystem@design-system.clinician-frontend.kry.se/?path=/story/theme--colours
 */
export const primaryBlack = '#0a161f';
export const secondaryBlack = '#4c545b';
export const hintText = '#8d9295';
export const divider = '#dcdddd';
export const action = '#006064';
export const backgroundHighlight = '#d9e7e8';
export const backgroundDefault = '#f0f3f4';
export const white = '#fff';
export const critical = '#ff515c';
export const criticalText = '#b0132b';
export const criticalBackground = '#ffd2d2';
export const starred = '#ebb500';
export const warning = '#ff9732';
export const warningBackground = '#ffd89e';
export const hover = '#0a7e85';
export const focus = '#03a3f4';
export const focusBackground = '#cdf1fd';
