/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { memo } from 'react';
import { Alert, Snackbar } from '..';
import { spacing } from '../../style';
import type { VisibleError } from '../../model';

interface Props {
  readonly errors: readonly VisibleError[];
  readonly filterErrors: (
    callbackFn: (
      value: VisibleError,
      index?: number,
      array?: readonly VisibleError[]
    ) => boolean,
    thisArg?: any
  ) => void;
}

const Errors = ({ errors, filterErrors }: Props) => (
  <>
    {Boolean(errors.length) && (
      <Snackbar open>
        <div>
          {errors.map((error) => (
            <Alert
              key={error.message}
              onClose={() => {
                filterErrors((otherError) => otherError !== error);
              }}
              severity='warning'
              css={css`
                margin-top: ${spacing};
              `}
            >
              {error.message}
            </Alert>
          ))}
        </div>
      </Snackbar>
    )}
  </>
);

export default memo(Errors);
