/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import { memo, useState } from 'react';
import { useList } from 'react-use';
import Compare from './Compare';
import Errors from './Errors';
import { Button, SelectPool, TableViewIcon, Typography } from '..';
import { spacing, spacingLg } from '../../style';
import type { Import, VisibleError } from '../../model';

const ImportForm = () => {
  const [errors, { filter: filterErrors, push: addError }] =
    useList<VisibleError>([]);
  const [importing, setImporting] = useState(false);

  return (
    <>
      <Formik initialValues={{ apiKey: '' }} onSubmit={(_: Import) => {}}>
        {({ setFieldValue, values: { apiKey, file } }) => (
          <Form
            css={css`
              flex-grow: 1;
              padding-top: ${spacingLg};

              display: flex;
              flex-direction: column;
            `}
          >
            <div
              css={css`
                margin-bottom: ${spacingLg};
              `}
            >
              <SelectPool apiKey={apiKey} importing={importing} />
            </div>
            <div
              css={css`
                margin-bottom: ${spacingLg};
              `}
            >
              <Button
                component='label'
                startIcon={<TableViewIcon />}
                variant='contained'
                disabled={importing}
                css={css`
                  margin-right: ${spacing};
                `}
              >
                Upload Spreadsheet
                <input
                  accept='.csv, .xlsx'
                  onChange={({ currentTarget: { files } }) => {
                    setFieldValue('file', files?.[0]);
                  }}
                  type='file'
                  hidden
                />
              </Button>
              <Typography component='span'>{file?.name}</Typography>
            </div>
            {Boolean(apiKey) && file && (
              <Compare
                addError={addError}
                apiKey={apiKey}
                file={file}
                importing={importing}
                setImporting={setImporting}
              />
            )}
          </Form>
        )}
      </Formik>
      <Errors errors={errors} filterErrors={filterErrors} />
    </>
  );
};

export default memo(ImportForm);
