import { useMemo } from 'react';
import { partition } from '.';
import type { ExternalInsuranceEntry } from '../api';
import type { Partition } from '../model';

export const usePartition = (
  spreadsheetCodes: readonly string[] | undefined,
  backendCodes: readonly ExternalInsuranceEntry[] | undefined
): Partition | undefined =>
  useMemo(
    () =>
      spreadsheetCodes &&
      backendCodes &&
      partition(spreadsheetCodes, backendCodes),
    [spreadsheetCodes, backendCodes]
  );
