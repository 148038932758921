import { read } from 'xlsx';

const headerNames: readonly string[] = ['Avtalenummer', 'AvtaleNummer'];

export const getCodesFromSpreadsheet = async (
  file: File
): Promise<readonly string[]> => {
  const buffer = await file.arrayBuffer();
  const workBook = read(buffer, { type: 'buffer' });
  const codes: string[] = [];

  Object.values(workBook.Sheets).forEach((sheet) => {
    Object.entries(sheet).forEach(([key, { w }]) => {
      if (key.startsWith('A') && w && !headerNames.includes(w)) {
        codes.push(w);
      }
    });
  });

  return codes;
};
