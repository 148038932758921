import { TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useRef } from 'react';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';

export type TextFieldProps = MuiTextFieldProps & {
  /**
   * Name attribute of the `input` element.
   */
  readonly name: string;
};

export const TextField = (props: TextFieldProps) => {
  const { autoFocus, name, select } = props;

  // Automatically use Formik.
  const [{ onChange, value }] = useField(name);

  // Autofocus the TextField after it's added to the DOM.
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const timeout = setTimeout(() => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
      }
    }, 1);

    return () => clearTimeout(timeout);
  }, [autoFocus]);

  return (
    <MuiTextField
      id={name.replaceAll('.', '-')}
      inputRef={inputRef}
      margin='dense'
      onChange={onChange}
      value={select && value === undefined ? '' : value}
      variant='outlined'
      fullWidth
      {...props}
    />
  );
};
