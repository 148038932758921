import { prettyNumber, prettyPlural } from '.';

// TODO: Unit test.
export const prettyCount = (
  name: string,
  count: readonly unknown[] | number | null | undefined
) => {
  if (count && Array.isArray(count)) {
    count = count.length;
  }

  const namePart = prettyPlural(name, count);

  if (typeof count === 'number') {
    const countPart = count === 0 ? 'No' : prettyNumber(count);
    return `${countPart} ${namePart}`;
  }

  return namePart;
};
