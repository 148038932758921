import { mapKeys, pickBy, sum } from 'lodash';
import type { ExternalInsuranceEntry } from '../api';
import type { Partition } from '../model';

export const partition = (
  spreadsheetCodes: readonly string[],
  backendCodes: readonly ExternalInsuranceEntry[]
): Partition => {
  const alreadyAdded: Record<string, ExternalInsuranceEntry> = {};
  const duplicateCounts: Record<string, number> = {};
  const newCodes: Record<string, true> = {};
  const backendCodesByCode = mapKeys(backendCodes, 'external_id');

  spreadsheetCodes.forEach((code) => {
    const duplicates = duplicateCounts[code];
    if (typeof duplicates === 'number') {
      duplicateCounts[code] = duplicates + 1;
      return;
    } else {
      duplicateCounts[code] = 0;
    }

    const backendCode = backendCodesByCode[code];
    if (backendCode) {
      alreadyAdded[code] = backendCode;
    } else {
      newCodes[code] = true;
    }
  });

  return {
    alreadyAdded,
    duplicateCount: sum(Object.values(duplicateCounts)),
    duplicateCounts: pickBy(duplicateCounts, (count) => count > 0),
    newCodes,
    totalCount: spreadsheetCodes.length,
  };
};
